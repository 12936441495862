import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import { getDAM3BranchNames } from '../../../../api'
import { SearchableSelect } from '../../../../SearchableSelect/SearchableSelect'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingBranchesWidget = (props) => {
    const { onChange, formContext, id } = props
    const [branchNames, setBranchNames] = useState([])
    const [branchName, setBranchName] = useState('')
    const [autofilledBranchName, setAutofilledBranchName] = useState(false)
    const [prevBranchName, setPrevBranchName] = useState('')
    const [fetchingBranches, setFetchingBranches] = useState(false)
    const [prevFormContext, setPrevFormContext] = useState('')
    const [didFetchBranchesCase2, setDidFetchBranchesCase2] = useState(false)

    // Extract index from id (e.g., "root_run_version_0_contingency" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const classes = useStyles()

    const handleChange = (event) => {
        setBranchName(event.target.value)
    }

    useEffect(() => {
        if (
            branchName !== null &&
            branchName !== undefined &&
            branchName !== '' &&
            branchName !== prevBranchName
        ) {
            onChange(branchName)
            setPrevBranchName(branchName)
        }
    }, [branchName, prevBranchName, onChange])

    useEffect(() => {
        const asyncFetchGetDAM3BranchNames = async (
            comparisonType,
            DAM3PipelineID,
            DAM3PipelineID2,
            ercotDate,
            ercotHour
        ) => {
            if (
                comparisonType !== null &&
                comparisonType !== undefined &&
                DAM3PipelineID !== null &&
                DAM3PipelineID !== undefined &&
                DAM3PipelineID2 !== null &&
                DAM3PipelineID2 !== undefined &&
                ercotDate !== null &&
                ercotDate !== undefined &&
                ercotHour !== null &&
                ercotHour !== undefined &&
                fetchingBranches === false
            ) {
                setFetchingBranches(true)
                const res = await getDAM3BranchNames(
                    comparisonType,
                    DAM3PipelineID,
                    DAM3PipelineID2,
                    ercotDate,
                    ercotHour
                )
                if (res.data !== null) {
                    // Transform the array of strings into an array of objects,
                    // to be able to use searchable select
                    setBranchNames(
                        res.data.map((s) => ({
                            label: s,
                            value: s,
                        }))
                    )
                } else {
                    setBranchNames([])
                }
                setFetchingBranches(false)
            }
        }
        // We only reload branches if what changed was comparisonType, dam3ID, dam date or dam hour
        if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID &&
            formContext.run_version &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].modelDateDAM &&
            formContext.run_version[runVersionIndex].modelHourDAM &&
            formContext.run_version[runVersionIndex].comparisonType === '1'
        ) {
            if (
                prevFormContext === '' ||
                (prevFormContext &&
                    prevFormContext.pipeline_settings &&
                    formContext.pipeline_settings.DAM3PipelineID &&
                    prevFormContext.run_version &&
                    prevFormContext.run_version[runVersionIndex] &&
                    prevFormContext.run_version[runVersionIndex].modelDateDAM &&
                    prevFormContext.run_version[runVersionIndex].modelHourDAM &&
                    (prevFormContext.run_version[runVersionIndex]
                        .modelDateDAM !==
                        formContext.run_version[runVersionIndex].modelDateDAM ||
                        prevFormContext.run_version[runVersionIndex]
                            .modelHourDAM !==
                            formContext.run_version[runVersionIndex]
                                .modelHourDAM ||
                        prevFormContext.pipeline_settings.DAM3PipelineID !==
                            formContext.pipeline_settings.DAM3PipelineID ||
                        prevFormContext.pipeline_settings.comparisonType !==
                            formContext.pipeline_settings.comparisonType))
            ) {
                setPrevFormContext(formContext)
                asyncFetchGetDAM3BranchNames(
                    formContext.run_version[runVersionIndex].comparisonType,
                    formContext.pipeline_settings.DAM3PipelineID,
                    -1,
                    formContext.run_version[runVersionIndex].modelDateDAM,
                    formContext.run_version[runVersionIndex].modelHourDAM
                )
            }
        } else if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID &&
            formContext.run_version &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].DAM3PipelineID2 &&
            formContext.run_version[runVersionIndex].comparisonType === '2' &&
            (didFetchBranchesCase2 === false ||
                prevFormContext.pipeline_settings.DAM3PipelineID !==
                    formContext.pipeline_settings.DAM3PipelineID ||
                prevFormContext.run_version[runVersionIndex].DAM3PipelineID2 !==
                    formContext.run_version[runVersionIndex].DAM3PipelineID2)
        ) {
            // if comparisonType = 2 then we dont need ercot date
            setPrevFormContext(formContext)
            setDidFetchBranchesCase2(true)
            asyncFetchGetDAM3BranchNames(
                formContext.run_version[runVersionIndex].comparisonType,
                formContext.pipeline_settings.DAM3PipelineID,
                formContext.run_version[runVersionIndex].DAM3PipelineID2,
                'NA', // this param won't be used in this case but needs to not be empty for schema to work
                0
            )
        }
    }, [
        formContext,
        prevFormContext,
        runVersionIndex,
        fetchingBranches,
        didFetchBranchesCase2,
    ])

    // Autofill branchName with value from run_version 0 if user hasn't selected any
    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[0] &&
            formContext.run_version[0].branchName &&
            formContext.run_version[0].branchName !== '' &&
            // eslint-disable-next-line
            branchNames != [] &&
            branchName === ''
        ) {
            const timeout = setTimeout(
                () => {
                    if (!autofilledBranchName) {
                        setAutofilledBranchName(true)
                        setBranchName(formContext.run_version[0].branchName)
                    }
                },
                11 + runVersionIndex * 11
            )

            return () => clearTimeout(timeout)
        }
    }, [
        branchNames,
        branchName,
        formContext,
        runVersionIndex,
        autofilledBranchName,
    ])

    return (
        <div>
            {fetchingBranches ? (
                <div>
                    <br />
                    Loading branches, please wait...
                </div>
            ) : branchNames.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <SearchableSelect
                        key={branchName}
                        label="Branch name *"
                        value={branchName}
                        onChange={handleChange}
                        options={branchNames}
                        keyPropFn={(option) => option.value}
                        valuePropFn={(option) => option.value}
                        showAll
                        removeSelectionText
                        alwaysFocusTextFieldOnEnter
                        formControlProps={{ fullWidth: true }}
                    />
                </FormControl>
            ) : (
                <div></div>
            )}
        </div>
    )
}
export default DAMPostProcessingBranchesWidget
