import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import { InputAdornment, TextField, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'

import debounce from 'lodash/debounce'

const renderInputComponent = (inputProps) => {
    const { classes, className, disabled, ...other } = inputProps

    return (
        <TextField
            disabled={disabled}
            {...other}
            margin="dense"
            variant="outlined"
            className={classes.input}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" style={{ height: '55px' }}>
                        <SearchIcon className={classes.icon} />
                    </InputAdornment>
                ),
            }}
        />
    )
}

const styles = (theme) => ({
    suggestionsContainerOpen: {
        position: 'absolute',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        position: 'absolute',
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: 200,
        overflow: 'auto',
        zIndex: 100,
        backgroundColor: 'white',
        borderBottom: '1px solid green',
        borderLeft: '1px solid green',
        borderRight: '1px solid green',
        borderRadius: '0px 0px 5px 5px',
    },
    icon: {
        color: theme.palette.gray,
    },
    input: {
        width: '100%',
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '32px',
        letterSpacing: '-0.05px',
        margin: '0 0 10px 0',
        '& .MuiOutlinedInput-input': {
            height: '35px',
        },
    },
})

class Autocomplete extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            inputValue: props.inputValue || '',
            suggestions: [],
        }
        this.debouncedLoadSuggestions = debounce(
            props.getSuggestions,
            props.debounceTimeout
        )
    }

    // Avoid overwriting the inputValue when the user is editing
    componentDidUpdate(prevProps) {
        if (
            this.props.inputValue !== prevProps.inputValue &&
            this.state.inputValue !== this.props.inputValue
        ) {
            // Sync only if the inputValue has changed and the user is not actively typing
            if (this.props.inputValue !== this.state.inputValue) {
                this.setState({
                    inputValue: this.props.inputValue || '',
                })
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            suggestions: nextProps.suggestions,
        })
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debouncedLoadSuggestions(value)
    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }

    handleChange = (event, { newValue }) => {
        // For some reason if a user has selected an option, and then clicks the same option
        // in the suggestion list, the text is erased. Here we prevent that specific case
        if (event.type == 'click' && this.props.inputValue && newValue == '') {
            return
        }
        // If the user clicks away let's use this value
        const { onValueChanged } = this.props
        onValueChanged(newValue)
        this.setState({
            inputValue: newValue || '',
        })
    }

    render() {
        const {
            classes,
            getSuggestionValue,
            onSuggestionSelected,
            renderSuggestion,
            placeholder,
            disabled,
        } = this.props

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
            onSuggestionSelected,
        }

        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={{
                    classes,
                    disabled: disabled,
                    placeholder: placeholder,
                    value: this.state.inputValue,
                    onChange: this.handleChange,
                }}
                theme={{
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={(options) => (
                    <Paper {...options.containerProps}>
                        {options.children}
                    </Paper>
                )}
            />
        )
    }
}

Autocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    debounceTimeout: PropTypes.number,
    getSuggestions: PropTypes.func.isRequired,
    getSuggestionValue: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    suggestions: PropTypes.array,
    disabled: PropTypes.bool,
    onSuggestionSelected: PropTypes.func.isRequired,
    onValueChanged: PropTypes.func,
}

Autocomplete.defaultProps = {
    debounceTimeout: 500,
    getSuggestions: () => {
        return []
    },
    getSuggestionValue: () => '',
    onSuggestionSelected: () => {},
    renderSuggestion: () => {},
    onValueChanged: () => {},
    suggestions: [],
    inputValue: '',
    placeholder: 'Search ...',
    disabled: false,
}

export default withStyles(styles)(Autocomplete)
