import { Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import Autocomplete from '../Autocomplete'
import { useState } from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    gridItem: {
        width: '100%',
        paddingLeft: '.5rem',
    },
    searchItem: {
        width: '100%',
    },
    textField: {
        display: 'flex',
    },
}))

const MiniMasterSearchWidget = (props) => {
    const classes = useStyles()

    const { onChange } = props
    const [mimSelection, setMiMSelection] = useState(null)
    const [mimSearchResults, setMiMSearchResults] = useState([])

    const handleChange = (event, selection) => {
        setMiMSelection(selection.suggestion)
        onChange(selection.suggestion.id)
    }

    const getSuggestions = async (term) => {
        setMiMSelection(null)
        if (term.length >= 2) {
            let res = await axios.get(
                `/pipeline/search?pipeline_name=mini_master_pipeline&term=${term}`
            )
            if (res.status !== 200) {
                setMiMSearchResults([])
            }
            setMiMSearchResults(res.data)
        }
    }

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                className={classes.searchItem}
            >
                <Typography variant="body2">
                    {suggestion.id} - {suggestion.name}
                </Typography>
            </MenuItem>
        )
    }

    return (
        <Grid container alignItems="flex-start" direction="row">
            <Grid item xs={12}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                >
                    MiniMaster Pipeline ID *
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    placeholder="Search MiM Pipelines"
                    getSuggestions={getSuggestions}
                    suggestions={mimSearchResults}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={(event, suggestion) =>
                        handleChange(event, suggestion)
                    }
                    inputValue={get(mimSelection, 'id', '').toString()}
                />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Name"
                    disabled={true}
                    value={get(mimSelection, 'name', '')}
                />
            </Grid>
        </Grid>
    )
}
export default MiniMasterSearchWidget
