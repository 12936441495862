import { Grid, Typography } from '@material-ui/core'
import Form from '@rjsf/material-ui'
import validator from '@rjsf/validator-ajv8'
import widgets from './Schemas/Widgets'
import { useState, useEffect } from 'react'
import ObjectFieldTemplate from './CustomTemplates/ObjectFieldTemplate'
import axios from 'axios'

const CreateNMVPipelineFromSuspiciousBranches = ({
    history,
    showAlert,
    setLoading,
    uiSchema,
    schema,
}) => {
    const [formData, setFormData] = useState({})
    const [prevFormData, setPrevFormData] = useState({})

    const calculateSuspiciousBranches = async (event) => {
        // Check that the input data makes sense
        let fd = event.formData
        if (
            fd.checkDAM3Branches === false &&
            fd.checkMiMBranches === false &&
            fd.checkPortfolioExposureBranches === false
        ) {
            showAlert(
                'Please select at least one option for branches to visualize, either MiniMaster Possibly Overestimated, Portfolio Exposure, or DAM 3 High ELV'
            )
            return
        }
        if (fd.checkDAM3Branches === true) {
            if (fd.DAM3PipelineID === null) {
                showAlert('Please input a DAM Post Processing Pipeline ID')
                return
            } else if (fd.DAM3Scenario === null) {
                showAlert('Please select a DAM Post Processing Scenario')
                return
            } else if (fd.elvCutoff === null) {
                showAlert('Please input a minimum ELV value to filter branches')
                return
            }
        }
        if (fd.checkMiMBranches === true) {
            if (fd.MiniMasterPipelineID === null) {
                showAlert(
                    'Please input a MiniMaster Pipeline ID for Possibly Overestimated lines'
                )
                return
            } else if (fd.elvTimesNetAcqCutoff === null) {
                showAlert(
                    'Please input a minimum ELV * NetAcq value to filter branches'
                )
            }
        }
        if (fd.checkPortfolioExposureBranches === true) {
            if (fd.PEMiniMasterPipelineID === null) {
                showAlert(
                    'Please input a MiniMaster Pipeline ID for Portfolio Exposure'
                )
                return
            } else if (fd.exposureCutoff === null) {
                showAlert(
                    'Please input a minimum exposure value to filter branches'
                )
            }
        }

        // Form data looks good, send to API
        showAlert('Calculating branches, please wait...')
        let pipeline_name = 'nmv_pipeline'
        try {
            const res = await axios.post(
                `/dam_post_processing/create_nmv_pipeline_from_misvalued_branches`,
                fd
            )
            history.push(`/${pipeline_name}/${res.data.pipeline_id}`)
            history.go(0)
        } catch (error) {
            if (error.response.status === 400) {
                showAlert(error.response.data)
            } else {
                showAlert(
                    'An unkown error occurred.  Please try again momentarily.'
                )
            }
            setLoading(false)
        }
    }

    const handleChange = (event) => {
        if (event.formData !== prevFormData) {
            let newFormData = event.formData
            if (newFormData.hasOwnProperty('DAM3PipelineID')) {
                // Add pipeline_settings.DAM3PipelineID so that the scenario widgets works out of the box
                newFormData = {
                    ...newFormData,
                    pipeline_settings: {
                        DAM3PipelineID: newFormData.DAM3PipelineID,
                    },
                }
            }
            setFormData(newFormData)
        }
    }

    useEffect(() => {
        if (formData !== prevFormData) {
            setPrevFormData(formData)
            console.log(formData)
        }
    }, [formData, prevFormData])

    return (
        <div>
            <Grid item xs={12}>
                <Typography variant="h3" color="primary">
                    Create visualizations for possibly misvalued branches
                </Typography>
            </Grid>

            <Form
                uiSchema={uiSchema}
                schema={schema}
                validator={validator}
                widgets={widgets}
                formContext={formData}
                formData={formData}
                templates={{ ObjectFieldTemplate }}
                onChange={handleChange}
                onSubmit={calculateSuspiciousBranches}
            />
            <br />
        </div>
    )
}
export default CreateNMVPipelineFromSuspiciousBranches
