import { Component } from 'react'
import {
    Button,
    CircularProgress,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles,
    Tooltip,
} from '@material-ui/core'
import { withRouter } from 'react-router'
import Layout from '../Layout'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import ConfirmationDialog from './Components/ConfirmationDialog'
import axios from 'axios'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import CancelOrRestart from './Components/CancelOrRestart'
import AddRunScenario from './Components/AddRunScenario'
import PipelineDeletionWidget from './Components/PipelineDeletionWidget'
import { SocketProvider } from '../WebSockets/SocketContext'
import { get } from 'lodash'
import { convertToLocalTime } from '../Utils/TimeUtil'

const styles = (theme) => ({
    addButton: {
        float: 'right',
        margin: '8px',
    },
    sectionHeader: {
        padding: '1.2rem 0',
        display: 'flex',
        flexWrap: 'nowrap',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    clickableRow: {
        cursor: 'pointer',
        userSelect: 'none',
    },
    unclickableRow: {
        cursor: 'not-allowed',
        userSelect: 'none',
    },
    paper: {
        maxHeight: 435,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

class PipelineList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pipelines: [],
            loading: true,
            totalCount: 0,
            page: 0,
            rowsPerPage: 10,
            alertOpen: false,
            alertMessage: '',
            dialogOpen: false,
            pipelineActionId: null,
            pipelineAction: null,
            deletedPipelineExecutionIds: {},
        }

        this.showAlert = this.showAlert.bind(this)
    }

    showAlert(message) {
        this.setState({
            alertOpen: true,
            alertMessage: message,
        })
    }

    handlePageChange = async (event, newPage) => {
        this.setState(
            {
                page: newPage,
            },
            this.updatePipelines
        )
    }

    handleChangeRowsPerPage = async (event) => {
        this.setState(
            {
                rowsPerPage: event.target.value,
            },
            this.updatePipelines
        )
    }

    handlePipelineAction = async (idToApplyAction, action) => {
        this.setState({ loading: true })
        try {
            if (action === 'add scenarios') {
                this.props.history.push({
                    pathname: `/${this.props.pipeline_name}/create/scenarios/${idToApplyAction}`,
                })
                return
            }
            const res = await axios.post(
                `/pipeline/${action}/${idToApplyAction}?pipeline_name=${this.props.pipeline_name}`
            )

            let successMessage = get(res.data, 'message', null)
            successMessage =
                successMessage === null
                    ? get(res.data, 'status', null)
                    : successMessage

            if (successMessage !== 'Success') {
                this.showAlert(res.data.message)
            } else {
                if (action === 'delete') {
                    this.setState((previousState) => {
                        let deletedPipelineExecutionIds = Object.assign(
                            {},
                            previousState.deletedPipelineExecutionIds
                        )
                        deletedPipelineExecutionIds[idToApplyAction] = get(
                            res.data,
                            'data.execution_id'
                        )
                        return {
                            deletedPipelineExecutionIds:
                                deletedPipelineExecutionIds,
                            pipelines: this.state.pipelines.map((p) =>
                                p.id === idToApplyAction
                                    ? { ...p, status: 'Deleting' }
                                    : p
                            ),
                        }
                    })
                } else {
                    this.props.history.go(0)
                }
            }
        } catch (error) {
            if (error.response.status === 400) {
                this.showAlert(`Error: ${error.response.data}`)
            } else {
                this.showAlert(
                    `Failed to ${action} pipeline. Contact an admin for more info.`
                )
            }
        }
        this.setState({ loading: false })
    }

    handlePipelineActionClick = (event, id, action) => {
        event.stopPropagation()
        this.setState({
            dialogOpen: true,
            pipelineActionId: id,
            pipelineAction: action,
        })
    }

    handleClose = (newValue) => {
        this.setState({ dialogOpen: false })

        if (newValue) {
            this.handlePipelineAction(
                this.state.pipelineActionId,
                this.state.pipelineAction
            )
        } else {
            this.setState({ idToApplyAction: null, pipelineAction: null })
        }
    }

    async componentDidMount() {
        await this.updatePipelines()
    }

    async componentDidUpdate(prevProps) {
        if (this.props.pipeline_name !== prevProps.pipeline_name)
            await this.updatePipelines()
    }

    async updatePipelines() {
        const results = await axios.get(
            `/pipeline/list?page=${this.state.page}&limit=${this.state.rowsPerPage}&pipeline_name=${this.props.pipeline_name}`
        )

        this.setState({
            loading: false,
            totalCount: results.data.count,
            pipelines: results.data.results,
        })
    }

    pipelineDeleting(pipelineId, status) {
        return (
            pipelineId in this.state.deletedPipelineExecutionIds ||
            status === 'deleting'
        )
    }

    render() {
        const { classes, history, pipeline_name } = this.props

        // TODO display at the right time the graph
        return (
            <Layout>
                {this.props.pipeline_name == 'nmv_pipeline' && (
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={() =>
                            history.push({
                                pathname: `/${pipeline_name}/createFromSuspiciousBranches`,
                            })
                        }
                    >
                        Create for Possibly Misvalued Branches
                    </Button>
                )}

                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={() =>
                        history.push({ pathname: `/${pipeline_name}/create` })
                    }
                >
                    Create New
                </Button>

                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    onClick={() =>
                        history.push({ pathname: `/${pipeline_name}/graph` })
                    }
                >
                    View graph
                </Button>
                <div style={{ marginBottom: '2rem' }}>
                    {this.state.pipelines &&
                        this.state.pipelines.length > 0 &&
                        !this.state.loading && (
                            <>
                                <Typography
                                    component="div"
                                    className={classes.sectionHeader}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Pipelines
                                    </Typography>
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Creator</TableCell>
                                            <TableCell>Created Date</TableCell>
                                            <TableCell>Run time</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell></TableCell>
                                            {this.props.pipeline_name ===
                                                'data_extraction_pipeline' && (
                                                <TableCell></TableCell>
                                            )}
                                            {this.props.pipeline_name ===
                                                'data_transformation_pipeline' && (
                                                <TableCell>
                                                    Run Scenario
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                Restart or cancel
                                            </TableCell>
                                            <TableCell>Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.pipelines.map((row) => (
                                            <TableRow
                                                className={
                                                    this.pipelineDeleting(
                                                        row.id,
                                                        row.status
                                                    )
                                                        ? classes.unclickableRow
                                                        : classes.clickableRow
                                                }
                                                key={row.id}
                                                onClick={(event) => {
                                                    if (
                                                        !this.pipelineDeleting(
                                                            row.id,
                                                            row.status
                                                        )
                                                    )
                                                        history.push(
                                                            `/${this.props.pipeline_name}/${row.id}`
                                                        )
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.id}
                                                </TableCell>
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.created_by}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {convertToLocalTime(
                                                        row.created_date
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {row.elapsed_time || 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    <StatusCell
                                                        status={row.status}
                                                    />
                                                </TableCell>
                                                {pipeline_name ===
                                                    'data_extraction_pipeline' && (
                                                    <TableCell
                                                        width="5%"
                                                        style={{ color: 'red' }}
                                                    >
                                                        {row.incomplete_dam_pipeline_scenario
                                                            ? 'Incomplete DAM Pipeline Scenario'
                                                            : ''}
                                                    </TableCell>
                                                )}
                                                <TableCell width="15%">
                                                    {this.state
                                                        .deletedPipelineExecutionIds[
                                                        row.id
                                                    ] && (
                                                        <SocketProvider>
                                                            <PipelineDeletionWidget
                                                                executionId={
                                                                    this.state
                                                                        .deletedPipelineExecutionIds[
                                                                        row.id
                                                                    ]
                                                                }
                                                            />
                                                        </SocketProvider>
                                                    )}
                                                </TableCell>
                                                {pipeline_name ===
                                                    'data_transformation_pipeline' && (
                                                    <TableCell>
                                                        <AddRunScenario
                                                            status={row.status}
                                                            pipeline_name={
                                                                pipeline_name
                                                            }
                                                            handleAction={(
                                                                event,
                                                                action
                                                            ) =>
                                                                this.handlePipelineActionClick(
                                                                    event,
                                                                    row.id,
                                                                    action
                                                                )
                                                            }
                                                            disabled={
                                                                this.pipelineDeleting(
                                                                    row.id,
                                                                    row.status
                                                                ) ||
                                                                (row.status !==
                                                                    'Complete' &&
                                                                    row.status !==
                                                                        'Cancelled')
                                                            }
                                                        />
                                                    </TableCell>
                                                )}
                                                <TableCell width="5%">
                                                    <CancelOrRestart
                                                        status={row.status}
                                                        handleAction={(
                                                            event,
                                                            action
                                                        ) =>
                                                            this.handlePipelineActionClick(
                                                                event,
                                                                row.id,
                                                                action
                                                            )
                                                        }
                                                        disabled={this.pipelineDeleting(
                                                            row.id,
                                                            row.status
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell width="5%">
                                                    <Tooltip title="Delete pipeline">
                                                        <Button
                                                            disabled={this.pipelineDeleting(
                                                                row.id,
                                                                row.status
                                                            )}
                                                            component="label"
                                                            color="primary"
                                                            startIcon={
                                                                <Delete />
                                                            }
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                this.handlePipelineActionClick(
                                                                    event,
                                                                    row.id,
                                                                    'delete'
                                                                )
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        )}

                    {this.state.loading && (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress color="primary" size={75} />
                        </div>
                    )}

                    {this.state.pipelines &&
                        this.state.pipelines.length <= 0 &&
                        !this.state.loading && (
                            <Typography
                                component="div"
                                className={classes.sectionHeader}
                            >
                                <Typography variant="h5" gutterBottom>
                                    No Pipelines... Create a new one!
                                </Typography>
                            </Typography>
                        )}

                    {this.state.pipelines &&
                        this.state.pipelines.length > 0 &&
                        !this.state.loading && (
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 25]}
                                component="div"
                                count={this.state.totalCount || 0}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handlePageChange}
                                onRowsPerPageChange={
                                    this.handleChangeRowsPerPage
                                }
                            />
                        )}
                </div>
                <Snackbar
                    open={this.state.alertOpen}
                    autoHideDuration={6000}
                    onClose={(e, r) => this.setState({ alertOpen: false })}
                >
                    <Alert
                        onClose={(e, r) => this.setState({ alertOpen: false })}
                        severity="error"
                    >
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
                <ConfirmationDialog
                    classes={{
                        paper: classes.paper,
                    }}
                    keepMounted
                    title={`Confirm ${this.state.pipelineAction} pipeline`}
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    dialogContent={`Are you sure you want to ${this.state.pipelineAction} pipeline ${this.state.pipelineActionId}?`}
                />
            </Layout>
        )
    }
}

const StatusCell = ({ status }) => {
    switch (status) {
        case 'Complete':
            return (
                <Tooltip title={status} style={{ color: '#4BB543' }}>
                    <CheckCircleIcon />
                </Tooltip>
            )
        case 'Running':
            return (
                <Tooltip title={status}>
                    <CircularProgress size={24} />
                </Tooltip>
            )
        case 'Deleting':
            return (
                <Tooltip title={status} style={{ color: '#FFA500' }}>
                    <DeleteSweepIcon />
                </Tooltip>
            )
        case 'Error':
            return (
                <Tooltip title={status} color="error">
                    <ErrorIcon />
                </Tooltip>
            )
        default:
            return <span>{status}</span>
    }
}

export default withRouter(withStyles(styles)(PipelineList))
