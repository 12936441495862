import { Button, Grid } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

const useStyles = makeStyles((theme) => ({
    gridItem: {
        width: '100%',
        paddingLeft: '.5rem',
        paddingTop: '1.1em',
        display: 'flex', // This makes the container a flexbox
        justifyContent: 'flex-end', // Align items to the right
    },
    uploadFileInput: {
        float: 'right',
        textTransform: 'unset',
        padding: '15px 20px',
    },
    searchItem: {
        width: '100%',
    },
    textField: {
        display: 'flex',
    },
}))

const NMVRunInputsBlankCSVWidget = (props) => {
    const classes = useStyles()

    const handleBlankFileDownloadClick = async () => {
        const res = await axios.get(
            `/dam_post_processing/nmv_run_versions_blank_file/`
        )
        const filename = 'nmv_run_versions.csv'
        const downloadURL = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = downloadURL
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }

    return (
        <Grid item xs={12} className={classes.gridItem}>
            <Button
                variant="outlined"
                component="label"
                color="primary"
                className={classes.uploadFileInput}
                startIcon={<CloudDownloadIcon />}
                onClick={async () => {
                    await handleBlankFileDownloadClick()
                }}
            >
                DOWNLOAD BLANK RUN VERSIONS CSV
            </Button>
        </Grid>
    )
}
export default NMVRunInputsBlankCSVWidget
