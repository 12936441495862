import { Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import Autocomplete from '../Autocomplete'
import { useState } from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    gridItem: {
        width: '100%',
        paddingRight: '.5rem',
    },
    searchItem: {
        width: '100%',
    },
    textField: {
        display: 'flex',
    },
}))

const DAMPostProcessingPipelineSearch = (props) => {
    const classes = useStyles()
    const { onChange, schema } = props

    // If in "Schema.json" we add 'includeAbcCutoff' as False the component ABC CutOff is not included
    const includeAbcCutoff = schema.includeAbcCutoff ?? true

    const [
        damPostProcessingPipelineSelection,
        setDAMPostProcessingPipelineSelection,
    ] = useState(null)
    const [
        damPostProcessingPipelineSearchResults,
        setDAMPostProcessingPipelineSearchResults,
    ] = useState([])

    const handleChange = (event, selection) => {
        setDAMPostProcessingPipelineSelection(selection.suggestion)
        setDAMPostProcessingPipelineSearchResults((prevSearchResults) => {
            return prevSearchResults.filter(
                (item) => item.id !== selection.suggestion.id
            )
        })
        onChange(selection.suggestion.id)
    }

    const getSuggestions = async (term) => {
        var currentValue
        setDAMPostProcessingPipelineSelection((currentState) => {
            if (currentState !== null) {
                currentValue = currentState.id.toString()
            }
            return currentState
        })
        if (term.length >= 2 && term !== currentValue) {
            let res = await axios.get(
                `/pipeline/search?pipeline_name=dam_post_processing_pipeline&term=${term}`
            )
            if (res.status !== 200) {
                setDAMPostProcessingPipelineSearchResults([])
            }
            setDAMPostProcessingPipelineSearchResults(res.data)
        }
    }

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                className={classes.searchItem}
            >
                <Typography variant="body2">
                    {suggestion.id} - {suggestion.name}
                </Typography>
            </MenuItem>
        )
    }

    const renderAbcCutoffIfNeeded = (renderComponent) => {
        return renderComponent ? (
            <Grid item xs={6} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Data Extraction Pipeline ABC Cutoff"
                    disabled={true}
                    value={get(
                        damPostProcessingPipelineSelection,
                        'abc_cutoff_date',
                        ''
                    )}
                />
            </Grid>
        ) : null
    }

    return (
        <Grid container alignItems="flex-start" direction="row">
            <Grid item xs={6} className={classes.gridItem}>
                <Autocomplete
                    placeholder="DAM Post Processing Pipeline"
                    getSuggestions={getSuggestions}
                    suggestions={damPostProcessingPipelineSearchResults}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={(event, suggestion) =>
                        handleChange(event, suggestion)
                    }
                    inputValue={get(
                        damPostProcessingPipelineSelection,
                        'id',
                        ''
                    ).toString()}
                />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Name"
                    disabled={true}
                    value={get(damPostProcessingPipelineSelection, 'name', '')}
                />
            </Grid>
            {renderAbcCutoffIfNeeded(includeAbcCutoff)}
        </Grid>
    )
}
export default DAMPostProcessingPipelineSearch
