import { useEffect, useState } from 'react'
import { FormControl, TextField } from '@material-ui/core'

const NMVPipelineErcotDateInputWidget = (props) => {
    const { onChange, formContext, id } = props
    const [date, setDate] = useState('')
    const [inputLabel, setInputLabel] = useState('ERCOT Network Model Date *')
    // Extract index from id (e.g., "root_run_version_0_generator" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const handleChange = (event) => {
        setDate(event.target.value)
    }

    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[0] &&
            formContext.run_version[0].modelDateDAM &&
            date === ''
        ) {
            const timeout = setTimeout(
                () => {
                    setDate(formContext.run_version[0].modelDateDAM)
                },
                runVersionIndex * 7 + 7
            )

            return () => clearTimeout(timeout)
        }
    }, [formContext, date, runVersionIndex])

    useEffect(() => {
        if (date !== null && date !== undefined && date !== '') {
            onChange(date)
        }
    }, [date, onChange])

    // Changes label text dynamically, even though the field is exactly the same
    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].comparisonType
        ) {
            if (
                formContext.run_version[runVersionIndex].comparisonType === '1'
            ) {
                setInputLabel('ERCOT Network Model Date *')
            } else if (
                formContext.run_version[runVersionIndex].comparisonType === '2'
            ) {
                setInputLabel('Bravos Network Model Date #2 *')
            }
        }
    }, [formContext, runVersionIndex])

    return (
        <FormControl>
            <TextField
                label={inputLabel}
                type="date"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={date}
                onChange={handleChange}
            />
        </FormControl>
    )
}
export default NMVPipelineErcotDateInputWidget
