import React from 'react'

const HeaderField = ({ schema }) => {
    const title = schema?.title
    return (
        <div>
            <h2>{title}</h2>
        </div>
    )
}

export default HeaderField
