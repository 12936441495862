import { useEffect, useState } from 'react'
import { getDAMLoopScenariosByPipeline } from '../../../api'
import { getDAM3ScenariosByPipeline } from '../../../api'
import {
    List,
    ListItem,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core'

const PortfolioStatsDAM3PipelineScenarioWidget = (props) => {
    const { value, onChange, formContext, schema } = props
    const [dam3Scenarios, setDam3Scenarios] = useState([])

    // We want to force the widget to only consider dam3 pipelines id introduced after the
    // component is loaded.
    const [dam3PipelineIdFalse, setDam3PipelineIdFalse] = useState(undefined)
    const [firstExecution, setFirstExecution] = useState(true)

    // If in "Schema.json" we add 'includeAbcCutoff' as False the component ABC CutOff is not included
    const includeAbcCutoff = schema.includeAbcCutoff ?? true
    useEffect(() => {
        const asyncFetchDAM3Scenarios = async () => {
            let retrievedScenarios = []
            if (
                formContext?.pipelineSettings.hasOwnProperty(
                    'damPostProcessingSettings'
                )
            ) {
                let dam3PipelineId =
                    formContext?.pipelineSettings['damPostProcessingSettings'][
                        'damPostProcessingPipelineId'
                    ]
                if (dam3PipelineId !== null && dam3PipelineId !== undefined) {
                    if (firstExecution) {
                        setDam3PipelineIdFalse(dam3PipelineId)
                    } else {
                        if (dam3PipelineIdFalse !== dam3PipelineId) {
                            const res =
                                await getDAM3ScenariosByPipeline(dam3PipelineId)
                            retrievedScenarios = res.data
                        }
                    }
                } else {
                    // In case of retyping the same dam3_pipeline_id
                    setDam3PipelineIdFalse(undefined)
                }
            }
            setFirstExecution(false)
            setDam3Scenarios(retrievedScenarios)
        }
        asyncFetchDAM3Scenarios()
    }, [formContext?.pipelineSettings])

    const handleCheckboxChange = (event) => {
        const optionValue = parseInt(event.target.value, 10)
        const isChecked = event.target.checked
        let newSelectedOptions

        if (isChecked) {
            newSelectedOptions = [...value, optionValue]
        } else {
            newSelectedOptions = value.filter((val) => val !== optionValue)
        }
        onChange(newSelectedOptions)
    }

    return (
        <Grid>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Available scenarios used in DAM 3:
            </Typography>
            <List>
                {dam3Scenarios.map((option) => (
                    <ListItem key={option}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.includes(option)}
                                    onChange={handleCheckboxChange}
                                    value={option.toString()}
                                />
                            }
                            label={option.toString()}
                        />
                    </ListItem>
                ))}
            </List>
        </Grid>
    )
}
export default PortfolioStatsDAM3PipelineScenarioWidget
