import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import { getDAM3GeneratorsByPipeline } from '../../../../api'
import { SearchableSelect } from '../../../../SearchableSelect/SearchableSelect'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingGeneratorsWidget = (props) => {
    const { onChange, formContext, id } = props
    const [generators, setGenerators] = useState([])
    const [generator, setGenerator] = useState('')
    const [autofilledGenerator, setAutofilledGenerator] = useState(false)
    const [prevGenerator, setPrevGenerator] = useState('')
    const [fetchingGenerators, setFetchingGenerators] = useState(false)
    const [didFetchGeneratorsCase2, setDidFetchGeneratorsCase2] =
        useState(false)
    const [prevFormContext, setPrevFormContext] = useState('')

    // Extract index from id (e.g., "root_run_version_0_generator" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const classes = useStyles()

    const handleChange = (event) => {
        setGenerator(event.target.value)
    }

    useEffect(() => {
        if (
            generator !== null &&
            generator !== undefined &&
            generator !== '' &&
            generator !== prevGenerator
        ) {
            onChange(generator)
            setPrevGenerator(generator)
        }
    }, [generator, prevGenerator, onChange])

    useEffect(() => {
        const asyncFetchGetDAM3Generators = async (
            comparisonType,
            DAM3PipelineID,
            DAM3PipelineID2,
            ercotDate,
            ercotHour
        ) => {
            if (
                comparisonType !== null &&
                comparisonType !== undefined &&
                DAM3PipelineID !== null &&
                DAM3PipelineID !== undefined &&
                DAM3PipelineID2 !== null &&
                DAM3PipelineID2 !== undefined &&
                ercotDate !== null &&
                ercotDate !== undefined &&
                ercotHour !== null &&
                ercotHour !== undefined
            ) {
                setFetchingGenerators(true)
                const res = await getDAM3GeneratorsByPipeline(
                    comparisonType,
                    DAM3PipelineID,
                    DAM3PipelineID2,
                    ercotDate,
                    ercotHour
                )
                setFetchingGenerators(false)
                if (res.data !== null) {
                    // Transform the array of strings into an array of objects,
                    // to be able to use searchable select
                    setGenerators(
                        res.data.map((s) => ({
                            label: s,
                            value: s,
                        }))
                    )
                } else {
                    setGenerators([])
                }
            }
        }

        // We only reload generators if what changed was comparisonType, dam3ID, dam date or dam hour
        if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID &&
            formContext.run_version &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].modelDateDAM &&
            formContext.run_version[runVersionIndex].modelHourDAM &&
            formContext.run_version[runVersionIndex].comparisonType === '1'
        ) {
            if (
                prevFormContext === '' ||
                (prevFormContext &&
                    prevFormContext.pipeline_settings &&
                    formContext.pipeline_settings.DAM3PipelineID &&
                    prevFormContext.run_version &&
                    prevFormContext.run_version[runVersionIndex] &&
                    prevFormContext.run_version[runVersionIndex].modelDateDAM &&
                    prevFormContext.run_version[runVersionIndex].modelHourDAM &&
                    (prevFormContext.run_version[runVersionIndex]
                        .modelDateDAM !==
                        formContext.run_version[runVersionIndex].modelDateDAM ||
                        prevFormContext.run_version[runVersionIndex]
                            .modelHourDAM !==
                            formContext.run_version[runVersionIndex]
                                .modelHourDAM ||
                        prevFormContext.pipeline_settings.DAM3PipelineID !==
                            formContext.pipeline_settings.DAM3PipelineID ||
                        prevFormContext.pipeline_settings.comparisonType !==
                            formContext.pipeline_settings.comparisonType))
            ) {
                setPrevFormContext(formContext)
                asyncFetchGetDAM3Generators(
                    formContext.run_version[runVersionIndex].comparisonType,
                    formContext.pipeline_settings.DAM3PipelineID,
                    -1,
                    formContext.run_version[runVersionIndex].modelDateDAM,
                    formContext.run_version[runVersionIndex].modelHourDAM
                )
            }
        } else if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID &&
            formContext.run_version &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].DAM3PipelineID2 &&
            formContext.run_version[runVersionIndex].comparisonType === '2' &&
            (didFetchGeneratorsCase2 === false ||
                prevFormContext.pipeline_settings.DAM3PipelineID !==
                    formContext.pipeline_settings.DAM3PipelineID ||
                prevFormContext.run_version[runVersionIndex].DAM3PipelineID2 !==
                    formContext.run_version[runVersionIndex].DAM3PipelineID2)
        ) {
            // if comparisonType = 2 then we dont need ercot date
            setPrevFormContext(formContext)
            setDidFetchGeneratorsCase2(true)
            asyncFetchGetDAM3Generators(
                formContext.run_version[runVersionIndex].comparisonType,
                formContext.pipeline_settings.DAM3PipelineID,
                formContext.run_version[runVersionIndex].DAM3PipelineID2,
                'NA', // this param won't be used in this case but needs to not be empty for schema to work
                0
            )
        }
    }, [formContext, prevFormContext, runVersionIndex, didFetchGeneratorsCase2])

    // Autofill generator with value from run_version 0 if user hasn't selected any
    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[0] &&
            formContext.run_version[0].genName &&
            formContext.run_version[0].genName !== '' &&
            // eslint-disable-next-line
            generators != [] &&
            generator === ''
        ) {
            const timeout = setTimeout(
                () => {
                    if (!autofilledGenerator) {
                        setAutofilledGenerator(true)
                        setGenerator(formContext.run_version[0].genName)
                    }
                },
                13 + runVersionIndex * 13
            )

            return () => clearTimeout(timeout)
        }
    }, [
        formContext,
        autofilledGenerator,
        generators,
        generator,
        runVersionIndex,
    ])

    return (
        <div>
            {fetchingGenerators ? (
                <div>
                    <br />
                    Loading generators...
                </div>
            ) : generators.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <SearchableSelect
                        key={generator}
                        label="Generator Name (Optional)"
                        value={generator}
                        onChange={handleChange}
                        options={generators}
                        keyPropFn={(option) => option.value}
                        valuePropFn={(option) => option.value}
                        showAll
                        removeSelectionText
                        alwaysFocusTextFieldOnEnter
                        formControlProps={{ fullWidth: true }}
                    />
                </FormControl>
            ) : (
                <div>
                    <br />
                    No generators found
                </div>
            )}
        </div>
    )
}
export default DAMPostProcessingGeneratorsWidget
