import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getDAM3ScenariosByPipeline } from '../../../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingSingleScenarioWidget = (props) => {
    const { onChange, formContext, id } = props
    const [scenarios, setScenarios] = useState([])
    const [scenario, setScenario] = useState('')
    const [prevScenario, setPrevScenario] = useState('')
    const [prevFormContext, setPrevFormContext] = useState('')

    let runVersionIndex = -1
    let widgetContext = ''
    if (id.includes('pipeline_settings') || id.includes('root_DAM3Scenario')) {
        widgetContext = 'pipeline_settings'
    } else if (id.includes('run_version')) {
        runVersionIndex = parseInt(id.split('_')[3], 10)
        widgetContext = 'run_version'
    }

    const classes = useStyles()

    const handleChange = (event) => {
        let scen = event.target.value
        if (scen !== prevScenario) {
            setScenario(Number(scen))
            onChange(Number(scen))
            setPrevScenario(Number(scen))
        }
    }

    useEffect(() => {
        const asyncFetchGetDAM3Scenarios = async (DAM3PipelineID) => {
            if (DAM3PipelineID !== null && DAM3PipelineID !== undefined) {
                console.log('Fetching scenarios for DAM3ID ' + DAM3PipelineID)
                const res = await getDAM3ScenariosByPipeline(DAM3PipelineID)
                if (res.data !== null) {
                    setScenarios(res.data)
                    console.log('Got scenarios!')
                }
            } else {
                setScenarios([])
            }
        }

        if (formContext && widgetContext !== '') {
            if (widgetContext === 'pipeline_settings') {
                if (
                    formContext.pipeline_settings &&
                    formContext.pipeline_settings.DAM3PipelineID
                ) {
                    if (
                        prevFormContext === '' ||
                        (prevFormContext &&
                            prevFormContext.pipeline_settings &&
                            prevFormContext.pipeline_settings.DAM3PipelineID !==
                                formContext.pipeline_settings.DAM3PipelineID)
                    ) {
                        setPrevFormContext(formContext)
                        asyncFetchGetDAM3Scenarios(
                            formContext.pipeline_settings.DAM3PipelineID
                        )
                    }
                }
            } else if (widgetContext === 'run_version') {
                if (
                    formContext.run_version &&
                    formContext.run_version[runVersionIndex] &&
                    formContext.run_version[runVersionIndex].DAM3PipelineID2
                ) {
                    if (
                        prevFormContext === '' ||
                        (prevFormContext.run_version &&
                            prevFormContext.run_version[runVersionIndex] &&
                            prevFormContext.run_version[runVersionIndex]
                                .DAM3PipelineID2 !==
                                formContext.run_version[runVersionIndex]
                                    .DAM3PipelineID2)
                    ) {
                        setPrevFormContext(formContext)
                        asyncFetchGetDAM3Scenarios(
                            formContext.run_version[runVersionIndex]
                                .DAM3PipelineID2
                        )
                    }
                }
            }
        }
    }, [formContext, prevFormContext, runVersionIndex, widgetContext])

    return (
        <div>
            {scenarios.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel id="scenario-select-label">
                        DAM 3 Scenario
                    </InputLabel>
                    <Select
                        labelId="scenario-select-label"
                        id="scenario-select"
                        value={scenario || ''}
                        onChange={handleChange}
                    >
                        {scenarios.map((scen) => (
                            <MenuItem value={scen} key={scen}>
                                {scen}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>Enter a DAM 3 ID to show available scenarios</div>
            )}
        </div>
    )
}
export default DAMPostProcessingSingleScenarioWidget
