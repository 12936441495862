import { MenuItem, Typography } from '@material-ui/core'
import Autocomplete from '../Autocomplete'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { get } from 'lodash'

const SearchDAMSlackBusName = (props) => {
    const { onChange, options, formContext } = props

    const [value, setValue] = useState(null)
    const [dataTransformationPipelineId, setDataTransformationPipelineId] =
        useState(null)
    const [searchResults, setSearchResults] = useState([])

    const handleChange = (event, selection) => {
        let selectedValue = get(selection.suggestion, 'name')
        setValue(selectedValue)
        event.preventDefault()
        onChange(selectedValue)
    }

    useEffect(() => {
        let changedDataTransformationPipelineId = get(
            formContext,
            'dataTransformationPipelineId',
            null
        )
        setDataTransformationPipelineId(changedDataTransformationPipelineId)
        if (itemInvalid(changedDataTransformationPipelineId)) {
            setValue(null)
            setDataTransformationPipelineId(null)
            onChange(null)
        }
    }, [setDataTransformationPipelineId, formContext, onChange])

    const itemInvalid = (params) => {
        return params === null || params === '' || params === undefined
    }

    const getSuggestions = async (term) => {
        var currentValue
        setValue((currentState) => {
            currentValue = currentState
            return currentState
        })

        if (term.length >= 2 && term !== currentValue) {
            var dtId
            setDataTransformationPipelineId((currentState) => {
                dtId = currentState
                return currentState
            })
            let res = await axios.get(
                `/pipeline/get_slack_bus_names_dam?term=${term}&data_transformation_pipeline_id=${dtId}`
            )
            if (res.status !== 200) {
                setSearchResults([])
            }
            setSearchResults(res.data)
        }
    }

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                style={{ width: '100%' }}
            >
                <Typography variant="body2">{suggestion.name}</Typography>
            </MenuItem>
        )
    }

    return (
        <Autocomplete
            placeholder={options.title}
            getSuggestions={getSuggestions}
            disabled={itemInvalid(dataTransformationPipelineId)}
            suggestions={searchResults}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(event, suggestion) =>
                handleChange(event, suggestion)
            }
            inputValue={value}
        />
    )
}
export default SearchDAMSlackBusName
