import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getCIMRedactedFilenames } from '../../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const CIMRedactedFilenameSelect = (props) => {
    const { onChange, formContext } = props
    const [cimFileNames, setCimFileNames] = useState([])
    const [filename, setFilename] = useState('')

    const classes = useStyles()

    const handleChange = (event) => {
        let fname = event.target.value
        setFilename(fname)
        onChange(fname)
    }

    useEffect(() => {
        const getFilenames = async () => {
            const res = await getCIMRedactedFilenames()
            if (res.error !== null) {
                // TODO: Figure how to inject errors into RSJF validation
                console.error('Error retrieving CIM Redacted File Names.')
            } else {
                setCimFileNames(res.data)
            }
        }

        getFilenames().catch(console.error)
    }, [formContext])
    return (
        <div>
            {cimFileNames.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel id="network-model-filename-select-label">
                        CIM Redacted File Name
                    </InputLabel>
                    <Select
                        labelId="network-model-filename-select-label"
                        id="network-model-filename-select"
                        value={filename}
                        onChange={handleChange}
                    >
                        {cimFileNames.map((tStamp) => (
                            <MenuItem value={tStamp} key={tStamp}>
                                {tStamp.toString()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>No CIM Redacted filenames available to choose</div>
            )}
        </div>
    )
}

export default CIMRedactedFilenameSelect
