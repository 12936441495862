import YearSelector from '../CustomWidgets/YearSelector'
import NodeSearch from '../CustomWidgets/NodeSearch'
import IntervalSplitDate from '../CustomWidgets/IntervalSplitDate'
import DataTransformationSearch from '../CustomWidgets/DataTransformationSearch'
import DataExtractionSearch from '../CustomWidgets/DataExtractionSearch'
import FeasibilityTestModelSearch from '../CustomWidgets/FeasibilityTestModelSearch'
import SearchDAMSlackBusName from '../CustomWidgets/SearchDAMSlackBusName'
import SlackBusNameAuctionSearch from '../CustomWidgets/SlackBusNameAuctionSearch'
import DAMPostProcessingScenarioWidget from '../CustomWidgets/DAMPostProccessingScenarioWidget'
import DAMPostProcessingAuctionTypeWidget from '../CustomWidgets/DAMPostProcessingAuctionTypeWidget'
import DAMPostProcessingAuctionMonthAndYearWidget from '../CustomWidgets/DAMPostProcessingAuctionMonthAndYearWidget'
import AuctionIIPipelineSearch from '../CustomWidgets/AuctionIIPipelineSearch'
import DAMPostProcessingPipelineSearch from '../CustomWidgets/DAMPostProcessingPipelineSearch'
import NMVPipelineBravosDateInputWidget from '../CustomWidgets/NMVPipelineWidgets/NMVPipelineBravosDateInputWidget'
import NMVPipelineErcotDateInputWidget from '../CustomWidgets/NMVPipelineWidgets/NMVPipelineErcotDateInputWidget'
import NMVPipelineBravosHourInputWidget from '../CustomWidgets/NMVPipelineWidgets/NMVPipelineBravosHourInputWidget'
import NMVPipelineErcotHourInputWidget from '../CustomWidgets/NMVPipelineWidgets/NMVPipelineErcotHourInputWidget'
import NMVRunInputsBlankCSVWidget from '../CustomWidgets/NMVPipelineWidgets/NMVRunInputsBlankCSVWidget'
import DAMPostProcessingPipelineSearchWithoutABCCutoff from '../CustomWidgets/NMVPipelineWidgets/DAMPostProcessingPipelineSearchWithoutABCCutoff'
import DAMPostProcessingSingleScenarioWidget from '../CustomWidgets/NMVPipelineWidgets/DAMPostProcessingSingleScenarioWidget'
import DAMPostProcessingContingenciesWidget from '../CustomWidgets/NMVPipelineWidgets/DAMPostProcessingContingenciesWidget'
import DAMPostProcessingBranchesWidget from '../CustomWidgets/NMVPipelineWidgets/DAMPostProcessingBranchesWidget'
import DAMPostProcessingGeneratorsWidget from '../CustomWidgets/NMVPipelineWidgets/DAMPostProcessingGeneratorsWidget'
import DAMPostProcessingLoadsWidget from '../CustomWidgets/NMVPipelineWidgets/DAMPostProcessingLoadsWidget'
import PortfolioStatsAuctionTypeWidget from '../CustomWidgets/PortfolioStatsAuctionTypeWidget'
import PortfolioStatsAuctionMonthAndYearWidget from '../CustomWidgets/PortfolioStatsAuctionMonthAndYearWidget'
import AuctionModelPipelineSearch from '../CustomWidgets/AuctionModelPipelineSearch'
import AuctionModelPipelineRoundSelect from '../CustomWidgets/AuctionModelPipelineRoundSelect'
import AuctionModelPipelineSelect from '../CustomWidgets/AuctionModelPipelineSelect'
import MMDPScenarioWidget from '../CustomWidgets/MMDPScenarioWidget'
import MiniMasterSearchWidget from '../CustomWidgets/MiniMasterSearchWidget'
import MMDPSearch from '../CustomWidgets/MMDPSearch'
import MNOSearch from '../CustomWidgets/MNOSearch'
import TUXSearch from '../CustomWidgets/TUXSearch'
import CAMSearch from '../CustomWidgets/CAMSearch'
import MNOPipelineSearch from '../CustomWidgets/MNOPipelineSearch'
import NetworkModelTimestampSelect from '../CustomWidgets/NetworkModelTimestampSelect'
import CIMRedactedFilenameSelect from '../CustomWidgets/CIMRedactedFilenameSelect'
import PortfolioStatsDAM3PipelineScenarioWidget from '../CustomWidgets/PortfolioStatsDAM3PipelineScenarioWidget'

const widgets = {
    yearSelector: YearSelector,
    nodeSearch: NodeSearch,
    intervalSplitDate: IntervalSplitDate,
    dataTransformationSearch: DataTransformationSearch,
    dataExtractionSearch: DataExtractionSearch,
    feasibilityTestModelSearch: FeasibilityTestModelSearch,
    searchDAMSlackBusName: SearchDAMSlackBusName,
    slackBusNameAuctionSearch: SlackBusNameAuctionSearch,
    damPostProcessingScenarioWidget: DAMPostProcessingScenarioWidget,
    damPostProcessingAuctionTypeWidget: DAMPostProcessingAuctionTypeWidget,
    damPostProcessingAuctionMonthAndYearWidget:
        DAMPostProcessingAuctionMonthAndYearWidget,
    auctionIIPipelineSearch: AuctionIIPipelineSearch,
    damPostProcessingPipelineSearch: DAMPostProcessingPipelineSearch,
    DAMPostProcessingPipelineSearchWithoutABCCutoff:
        DAMPostProcessingPipelineSearchWithoutABCCutoff,
    DAMPostProcessingSingleScenarioWidget:
        DAMPostProcessingSingleScenarioWidget,
    DAMPostProcessingBranchesWidget: DAMPostProcessingBranchesWidget,
    DAMPostProcessingContingenciesWidget: DAMPostProcessingContingenciesWidget,
    DAMPostProcessingGeneratorsWidget: DAMPostProcessingGeneratorsWidget,
    DAMPostProcessingLoadsWidget: DAMPostProcessingLoadsWidget,
    portfolioStatsAuctionTypeWidget: PortfolioStatsAuctionTypeWidget,
    portfolioStatsAuctionMonthAndYearWidget:
        PortfolioStatsAuctionMonthAndYearWidget,
    auctionModelPipelineSearch: AuctionModelPipelineSearch,
    auctionModelPipelineRoundSelect: AuctionModelPipelineRoundSelect,
    auctionModelPipelineSelect: AuctionModelPipelineSelect,
    MiniMasterSearchWidget: MiniMasterSearchWidget,
    mmdpScenarioWidget: MMDPScenarioWidget,
    mmdpSearch: MMDPSearch,
    mnoSearch: MNOSearch,
    tuxSearch: TUXSearch,
    camSearch: CAMSearch,
    mnoPipelineSearch: MNOPipelineSearch,
    networkModelTimestampSelect: NetworkModelTimestampSelect,
    NMVPipelineBravosDateInputWidget: NMVPipelineBravosDateInputWidget,
    NMVPipelineErcotDateInputWidget: NMVPipelineErcotDateInputWidget,
    NMVPipelineBravosHourInputWidget: NMVPipelineBravosHourInputWidget,
    NMVPipelineErcotHourInputWidget: NMVPipelineErcotHourInputWidget,
    NMVRunInputsBlankCSVWidget: NMVRunInputsBlankCSVWidget,
    CIMRedactedFilenameSelect: CIMRedactedFilenameSelect,
    PortfolioStatsDAM3PipelineScenarioWidget:
        PortfolioStatsDAM3PipelineScenarioWidget,
}
export default widgets
